import { getFirestore } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";
import { initializeApp } from "firebase/app";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAPCc965iSPuELJttGA-2CTr6FPPjA-oL8",
    authDomain: "nebraskajo-2fb01.firebaseapp.com",
    projectId: "nebraskajo-2fb01",
    storageBucket: "nebraskajo-2fb01.appspot.com",
    messagingSenderId: "1014922814552",
    appId: "1:1014922814552:web:49eaed4c5ff0135a0b962d"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
export const db = getFirestore(firebase);
export default firebase