import React from 'react';

import SEO from '../../common/SEO';
import BcrumbBannerOne from '../../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';

import AppHeader from '../../common/app/HeaderOne';
import AppCtaLayoutOne from '../../component/app/footer/CtaLayoutOne';
import AppFooterOne from '../../common/app/FooterOne';
import AppAboutFour from '../../component/app/about/AboutFour';
import { useTranslation } from 'react-i18next';



const AppAboutUs = () => {
    const { t } = useTranslation();
    return (
        <>
        <SEO title={t("about.aboutUsTitle")} />
  
            <main className="main-wrapper">
                 <AppHeader />
                <BcrumbBannerOne 
                title={t("about.aboutUsTitle")}
                paragraph =""
                styleClass="thumbnail-4"
                mainThumb="/images/home/about-2.jpg"
                />
                <div className='mt-4'></div>
                <AppAboutFour />
                <AppCtaLayoutOne />
                <AppFooterOne />
            </main>
        </>
    )
}

export default AppAboutUs;