import i18next from 'i18next';
import React, { useEffect } from 'react';
import App from './App';
import AppRtl from './AppRtl';
import { ChangeLanguageAttributes } from './i18n';
import ReactGA from 'react-ga4';

ReactGA.initialize("G-C7LGNJYKSG");

const AppWrap = () => {
	
	useEffect(() => {
		ChangeLanguageAttributes()
		ReactGA.send({ hitType: "pageview", page: `${window.location.pathname + window.location.search}`, title: "Page view" });
	}, [])
	
	return (
	  i18next.language == "ar" ? 
	 <App /> : <AppRtl/>
  )
}

export default AppWrap;
