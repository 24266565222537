import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaPinterestP, FaLinkedin, FaInstagram, FaVimeoV, FaDribbble, FaBehance, FaEnvelopeOpen, FaGoogle } from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from '../../utils';
import { useTranslation } from 'react-i18next';

const getServiceData = ServiceData;

const AppFooterOne = ({parentClass}) => {
    const { t } = useTranslation();
    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    {/* <div className="footer-social-link">
                        <ul className="list-unstyled">
                            <li><Link to="https://facebook.com/"><FaFacebookF /></Link></li>
                            <li><Link to="https://twitter.com/"><FaTwitter /></Link></li>
                            <li><Link to="https://www.linkedin.com/in/Nebraska_Jo_ksa/?fbclid=IwAR1a4CajLf7w1BG6saElbBV5ZMST85-LDbLaeWoioFhUe7EomKWF6Jf1kNQ"><FaLinkedin /></Link></li>
                            <li><Link to="https://www.instagram.com/Nebraska.Jo.ksa/?fbclid=IwAR0_2krOaFaaqE7Pd0Lo0AOIlxItg9XbWoFtVFf8xQlsQMMUizyyyXQSQdU"><FaInstagram /></Link></li>
                        </ul>
                    </div> */}
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            <div className="footer-widget border-end">
                                <div className="footer-newsletter">
                                    <h2 className="title">{t("home.GetInTouch")}</h2>
                                    {/* <p>{t("home.GetInTouchDesc")}</p> */}
                                    <div className=" mb--30">
                                    <ol className="list-style">
                                    <li>{t("home.MarketingAndCustomerServices")}: <a href='mailto:cmo@nebraskajo.com'>cmo@nebraskajo.com</a></li>
                                    <li>{t("home.LogisticsServices")}: <a href='mailto:clo@nebraskajo.com'>clo@nebraskajo.com</a></li>
                                    <li>{t("home.TechnicalServices")}: <a href='mailto:cto@nebraskajo.com'>cto@nebraskajo.com</a></li>
                                    <li>{t("home.GeneralManagement")}: <a href='mailto:ceo@nebraskajo.com'>ceo@nebraskajo.com</a></li>
                                    <li>{t("home.LegalServices")}:<a href='mailto:legal@nebraskajo.com'>legal@nebraskajo.com</a></li>
                                    <li>{t("home.AccountServices")}: <a href='mailto:cfo@nebraskajo.com'>cfo@nebraskajo.com</a></li>
                      
                                    </ol>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">{t("home.Phones")}</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled" >
                                            <li><a dir='ltr' href='tel:+962 7 9997 9949'>+962 7 9997 9949</a></li>
                                            {/* <li><a dir='ltr' href='tel:+962796363836'>+962796363836</a></li> */}
                                            {/* <li><a dir='ltr' href='tel:+966558551487'>+966558551487</a></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="footer-widget">
                                        <h6 className="widget-title">{t("home.Address")}</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li>{t("home.AddressDesc")}
                                                    </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="footer-widget">
                                      
                                        <div className="">
                                        <img src={process.env.PUBLIC_URL + "/images/nebraska-logo.png"} alt="thumbnail" />
                                        </div>
                                        <div className="widget widge-social-share">
                <div className="blog-share mt-4">
                 
                    <ul className="social-list list-unstyled mx-auto p-0">
                            <li><a target="_blank" href="https://www.facebook.com/NebraskaTechMena/about_contact_and_basic_info"><FaFacebookF /></a></li>
                            <li><a target="_blank" href="https://twitter.com/NebraskaTecMena"><FaTwitter /></a></li>
                            <li><a target="_blank" href="https://www.google.com/maps/place/Nebraska/@31.9540625,35.8344375,15z/data=!4m5!3m4!1s0x0:0x23daba3c8fd58eba!8m2!3d31.9540625!4d35.8344375"><FaGoogle /></a></li>
                            <li><a target="_blank" href="https://www.linkedin.com/in/nebraska-tech-mena-67a98b257/"><FaLinkedin /></a></li>
                            <li><a target="_blank" href="https://www.instagram.com/NebraskaTechmena/?fbclid=IwAR00HpQ0doXSNaVciSDPu1KqJig66Bff9aZCveV4ldq97nXmy1BeSbww-cU"><FaInstagram /></a></li>
                    </ul>
                </div>
            </div>
                                    </div>
                                </div>
                             
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by <a href="https://nebraskajo.com/">Nebraska</a>.</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            {/* <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms of Use</Link></li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default AppFooterOne;