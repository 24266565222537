import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import AppBanner from '../component/app/BannerFive';
import AppHeader from '../common/app/HeaderOne';
import AppAbout from '../component/app/AboutFive';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import AppTitle from '../component/app/title';
import AppCaseStudyProp from '../component/app/CaseStudyProp';
import AppCounterUpTwo from '../component/app/count/CounterUpTwo';
import AppBrandItem from '../component/app/brands/BrandItem';
import AppFooterOne from '../common/app/FooterOne';
import AppCtaLayoutOne from '../component/app/footer/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import AppCounterUp from '../component/app/count/CounterUp';
import { useTranslation } from "react-i18next";
import AppHomeNewsOne from '../component/app/news/homeNews';
import AdsBanner from '../common/app/AdsBanner';
import { getNews } from '../api/news';
import { getBrands } from '../api/brands';

const IndexPage = () => {
    const { t } = useTranslation();
    const [isNewsLoading, setIsNewsLoading] = useState(true);
    const [newsList, setNewsList] = useState([]);
    const [brandsList, setBrandsList] = useState([]);
     useEffect(() => {
        getNewsList();
        getBrandsList();
    }, []);
  
    const getNewsList = async () => {
      try {
        var list = await getNews();

        setNewsList(list);
        
      } catch (error) {
        console.error("error",error)
      }
      setIsNewsLoading(false);
    };
 
    const getBrandsList = async () => {
      try {
        var list = await getBrands();
        console.log("list",list)
        setBrandsList(list);
        
      } catch (error) {
        console.error("error",error)
      }
    };
 
    return (
        <>
            <SEO title="Home Page" />
            <main className="main-wrapper">
                <AppHeader />
                <AdsBanner />
    
                <div className='mt-4 pt-4'>
                </div>
               
                <div className='mt-4'>
                <AppAbout />
                </div>
                <div className="section section-padding">
                    <div className="container">
                    <SectionTitle 
                        subtitle=""
                        title={t("home.WorkWeFinished")}
                        description={t("home.WorkWeFinishedDesc")}
                        textAlignment=""
                        textColor=""
                    />
                    <div className="row">
                        <AppCounterUp colSize="col-lg-3 col-sm-6" layoutStyle="counterup-style-2" evenTopMargin="mt--90 mt_md--0" />
                    </div>
                    </div>
                </div>
                {newsList != null && newsList.length > 0&& 
                
                <AppHomeNewsOne newsList={newsList} />
                }
                <div id='our-services'>
                <AppTitle 
                title={t("home.OurServices")}
       
                />
                </div>
                <div className="pt--250 pt_lg--200 pt_md--100 pt_sm--80 case-study-page-area">
                    <div className="container">

                <AppCaseStudyProp />
                    </div>
                </div>
                {brandsList != null && brandsList.length > 0 &&
                    <>
                <AppTitle 
                title={t("home.OurPartners")}
                />
                <div className="section brand-wrap-area ">
                    <div className="container">
                        <div className='row'>
                        <AppBrandItem  dataList={brandsList}/>
                        </div>
                    </div>
                </div>
                    </>}
                <AppCtaLayoutOne />
                <AppFooterOne />
            
            </main>
        </>
    )
}

export default IndexPage;