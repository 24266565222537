import {
    collection,
    addDoc,
  } from "@firebase/firestore";
  import React from "react";
  import firebase, { db, storage } from "../firbase";
  
  var ApiOffers = collection(db, "offers");
  var ApiContact = collection(db, "contact");
  var ApiProject = collection(db, "project");
  
  export const createOffer = (data) => {
    addDoc(ApiOffers, data).then(() => {
     
    });
  };
  export const createContact = (data) => {
    addDoc(ApiContact, data).then(() => {
     
    });
  };
  export const createProjects = (data) => {
    addDoc(ApiProject, data).then(() => {
     
    });
  };


  