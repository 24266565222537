import i18next, { t } from "i18next";
import React, { useEffect, useState } from "react";

import StickyHeader from "../header/StickyHeader";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from 'swiper';

// Import Swiper styles
import "swiper/swiper.css";
import { getAds } from "../../api/ads";
const AdsBanner = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [adsList, setAdsList] = useState([]);
  useEffect(() => {
    getAdsList();
  }, []);

  const getAdsList = async () => {
    try {
      var list = await getAds();
      console.log("list", list)
      setAdsList(list);

    } catch (error) {
      console.error("error", error)
    }
    setIsLoading(false);
  };


  return (
    <>
      {isLoading ?
        <>

<div className="banner banner-style-5 d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/images/home/hero.jpg"})`, minHeight: "100vh", paddingTop: "0vh" }} >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-xl-12">
                      <div className="banner-content text-center">
                        <h1 className="title bannar-text">GROWTH BOSSES</h1>
                        <div>
                          <a href='#our-services' className="axil-btn btn-fill-primary btn-primary text-white">{t("nav.ViewServices")}</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-5">
                      {/* <div className="banner-form">
                              <div className="contact-form-box shadow-box">
                                  <h3 className="title">Get a free quote now</h3>
                                  <FormOne />
                              </div>
                          </div> */}
                    </div>
                  </div>
                </div>
              </div>
        </>
        :
        <>
          <Swiper
            id="Side_after"
            dir="ltr"
            spaceBetween={0}
            slidesPerView={1}
            loop={true}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            autoplay
            modules={[Autoplay]}
          >

            <SwiperSlide key={`ads_${0}`}>
              <div className="banner banner-style-5 d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/images/home/hero.jpg"})`, minHeight: "100vh", paddingTop: "0vh" }} >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-xl-12">
                      <div className="banner-content text-center">
                        <h1 className="title bannar-text">GROWTH BOSSES</h1>
                        <div>
                          <a href='#our-services' className="axil-btn btn-fill-primary btn-primary text-white">{t("nav.ViewServices")}</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-5">
                      {/* <div className="banner-form">
                              <div className="contact-form-box shadow-box">
                                  <h3 className="title">Get a free quote now</h3>
                                  <FormOne />
                              </div>
                          </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            {adsList.length > 0 &&
              adsList.map((item, index) => {
                return (
                  <SwiperSlide key={`ads_${index + 1}`}>
                    <div  className="banner banner-style-5 d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${item.img})`, minHeight: "100vh", paddingTop: "0vh" ,backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}} >
                      <div className="container">
                        <div className="row mt-4">
                          <div className="col-lg-12 col-xl-12 mt-4">
                            <div className="d-flex justify-content-center align-items-center">
                            <div className="banner-content text-center mt-4">
                              <h1 className="title  text-white pt-4 mt-4">{i18next.language == "ar" ? !!item.title ?item.title:  item.title_ar : item.title_en}</h1>
                              
                              <p className="  text-white"> {i18next.language == "ar" ? item.brief_ar : item.brief_en}</p>
                              {(item.link_to != null && item.link_to != "") &&

                                <div>
                                  <a href={item.link_to} className="axil-btn btn-fill-primary btn-primary text-white">{t("nav.ViewServices")}</a>
                                </div>
                              }
                            </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-5">
                            {/* <div className="banner-form">
                              <div className="contact-form-box shadow-box">
                                  <h3 className="title">Get a free quote now</h3>
                                  <FormOne />
                              </div>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                  </SwiperSlide>)
              })}
          </Swiper>
        </>


      }

    </>
  );
};

export default AdsBanner;
