import React, { useState ,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import CtaLayoutOne from '../../component/cta/CtaLayoutOne';
import BlogData from "../../component/app/news/NewsData.json";
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import BlogSidebar from '../../component/blog/BlogSidebar';
import BlogAuthor from '../../component/blog/BlogAuthor';
import Comment from '../../component/blog/Comment';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import SEO from '../../common/SEO';
import { FaPlay, FaAngleRight, FaAngleLeft } from "react-icons/fa";
import FsLightbox from 'fslightbox-react';
import Slider from "react-slick";
import i18next from 'i18next';
import AppHeader from '../../common/app/HeaderOne';
import AppCtaLayoutOne from '../../component/app/footer/CtaLayoutOne';
import AppFooterOne from '../../common/app/FooterOne';
import { useTranslation } from 'react-i18next';
// import BlogListOne from '../../component/app/news/NewsData.json';
import { getSingleNews } from '../../api/news';

const allBlogData = BlogData;


const AppNewsDetails = () => {
    const { t } = useTranslation();
    const params = useParams();
    const [isNewsLoading, setIsNewsLoading] = useState(true);
    const [newsItem, setNewsItem] = useState([]);
     useEffect(() => {
        getNewsItem();
    }, []);
  
    const getNewsItem = async () => {
      try {
        var list = await getSingleNews(params.id);
        console.log("list",list)
        setNewsItem(list);
        
      } catch (error) {
        console.error("error",error)
      }
      setIsNewsLoading(false);
    };






    const blogId = parseInt(params.id);







    const detailsBlog = newsItem;

    const [toggler, setToggler] = useState(false);

    function SlickNextArrow(props) {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}><FaAngleRight /></div>
        );
    }

    function SlickPrevArrow(props) {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}><FaAngleLeft /></div>
        );
    }

    var slideSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SlickNextArrow />,
        prevArrow: <SlickPrevArrow />,

    };

    return (
        <>
            <SEO title={i18next.language == "ar" ? detailsBlog.title : detailsBlog.title_en} />
            <AppHeader />
            <main className="main-wrapper">
               
                <BreadCrumbOne
                    title={i18next.language == "ar" ? detailsBlog.title : detailsBlog.title_en}
                    page={t("news.news")}
                />
                <div className="section-padding-equal  pt-4">
                    <div className="container">
                        <div className="row row-40">
                            <div className="col-lg-12">
                                <div className="single-blog">
                                    <div className="single-blog-content blog-grid">
                                        <div className="post-thumbnail mb-0">
                                            {
                                                Array.isArray(detailsBlog.large_thumb) ?
                                                    <Slider {...slideSettings} className="slick-arrow-nav">
                                                        {detailsBlog.large_thumb.map((data, index) => (
                                                            <div className="slide-item" key={index}>
                                                                <img src={`${detailsBlog.img}`} alt="Blog" />
                                                            </div>
                                                        ))}

                                                    </Slider>
                                                    : <img src={`${detailsBlog.img}`} alt="Blog" />
                                            }

                                            {detailsBlog.format === "video" ?
                                                <>
                                                    <div className="popup-video">
                                                        <button className="play-btn" onClick={() => setToggler(!toggler)}><FaPlay /></button>
                                                    </div>
                                                    <FsLightbox toggler={toggler} sources={['https://www.youtube.com/watch?v=1iIZeIy7TqM']} />
                                                </>
                                                : ""
                                            }

                                        </div>
                                        <h1 className='mt-5'>{i18next.language == "ar" ? detailsBlog.title : detailsBlog.title_en}</h1>
                                        <div className='mt-4' dangerouslySetInnerHTML={{__html:i18next.language == "ar" ? detailsBlog.description_ar : detailsBlog.description_en}} >
                                         
                                        </div>


                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>

                <AppCtaLayoutOne />
                <AppFooterOne />
              
            </main>
        </>
    )
}

export default AppNewsDetails;