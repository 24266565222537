import React from 'react';
import { useTranslation } from 'react-i18next';
import SectionTitle from '../../../elements/section-title/SectionTitle';
import AppNewsListOne from './homeNewsList';



const AppHomeNewsOne = ({newsList}) => {
    const { t } = useTranslation();
    return (
        <div className="section section-padding-equal">
           
            <div className="container">
                <SectionTitle 
                    subtitle={t("news.title")}
                    title={t("news.subtitle")}
                    description={t("news.desc")}
                    textAlignment=""
                    textColor=""
                />
                <div className="row g-0">
                    <AppNewsListOne colSize="col-xl-6"  newsList={newsList} />
                </div>
            </div>
            <ul className="shape-group-1 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-1.png"} alt="bubble" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-2.png"} alt="bubble" /></li>
                <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="bubble" /></li>
            </ul>
        </div>
    )
}

export default AppHomeNewsOne;