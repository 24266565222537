import React from 'react';
import { Link, useParams } from 'react-router-dom';
import BcrumbBannerTwo from '../../../elements/breadcrumb/BcrumbBannerTwo';
import SEO from '../../../common/SEO';
import AppHeader from '../../../common/app/HeaderOne';
import AppCtaLayoutOne from '../../../component/app/footer/CtaLayoutOne';
import AppFooterOne from '../../../common/app/FooterOne';
import MarketingSubSection from '../../../component/app/markting/subSectionTitle';
import { useTranslation } from 'react-i18next';
import trans from "../../../i18n/locales/en/translation.json"
// const allProjectData = ProjectData;

const MarketingProjectDetails = () => {
    const { t } = useTranslation();
    const params = useParams();
    // const projectSlug = params.slug;

    // const getProjectData = allProjectData.filter(data => slugify(data.title) === projectSlug);
    // const detailsProject = getProjectData[0];


    return (
        <>
            <SEO title={t("marketing.title")} />

            <main className="main-wrapper">
                <AppHeader />
                <BcrumbBannerTwo
                    title={t("marketing.title")}
                    paragraph=""
                    mainThumb="/images/marketing/hero.jpg"
                />
                <section className="section-padding single-portfolio-area">
                    <div className="container">
                        <div className="row">

                            <div className="mt-5">
                                <MarketingSubSection
                                    to={trans.marketing.titleS1}
                                    title={t("marketing.titleS1")}
                                    description={
                                        <>
                                            <p>
                                            {t("marketing.DescS1")}  

                                            </p>
                                        </>
                                    }
                                    img={"images/marketing/1.png"}
                                />
                            </div>

                            <div className="mt-5">
                                <MarketingSubSection
                                       to={trans.marketing.titleS2}
                                    title={t("marketing.titleS2")}  
                                    description={
                                        <>
                                            <p>
                                            {t("marketing.DescS2")} 
                                            </p>
                                        </>
                                    }
                                    img={"images/marketing/2.png"}
                                />
                            </div>

                            <div className="mt-5">
                                <MarketingSubSection
                                        to={trans.marketing.titleS3}
                                    title={t("marketing.titleS3")}  
                                    description={
                                        <>
                                            <p>
                                            {t("marketing.DescS3")} 
                                            </p>
                                        </>
                                    }
                                    img={"images/marketing/3.png"}
                                />
                            </div>

                            <div className="mt-5">
                                <MarketingSubSection
                                                to={trans.marketing.titleS4}
                                    title={t("marketing.titleS4")}  
                                    description={
                                        <>
                                            <p>
                                            {t("marketing.DescS4")} 
                                            </p>
                                        </>
                                    }
                                    img={"images/marketing/4.png"}
                                />
                            </div>

                            <div className="mt-5">
                                <MarketingSubSection
                                      to={trans.marketing.titleS5}
                                    title={t("marketing.titleS5")}
                                    description={
                                        <>
                                            <p>
                                            {t("marketing.DescS5")}

                                            </p>
                                        </>
                                    }
                                    img={"images/marketing/5.png"}
                                />
                            </div>

                            <div className="mt-5">
                                <MarketingSubSection
                                          to={trans.marketing.titleS6}
                                    title={t("marketing.titleS6")}
                                    description={
                                        <>
                                            <p>
                                            {t("marketing.DescS6")}
                                            </p>
                                        </>
                                    }
                                    img={"images/marketing/6.png"}
                                />
                            </div>

                            <div className="mt-5">
                                <MarketingSubSection
                                             to={trans.marketing.titleS7}
                                    title={t("marketing.titleS7")}
                                    description={
                                        <>
                                            <p>
                                            {t("marketing.DescS7")}
                                            </p>
                                        </>
                                    }
                                    img={"images/marketing/7.png"}
                                />
                            </div>

                            <div className="mt-5">
                                <MarketingSubSection
                                            to={trans.marketing.titleS8}
                                    title={t("marketing.titleS8")}
                                    description={
                                        <>
                                            <p>
                                            {t("marketing.DescS8")}
                                            </p>
                                        </>
                                    }
                                    img={"images/marketing/8-1.jpg"}
                                />
                            </div>



                        </div>
                    </div>
                </section>

                <AppCtaLayoutOne />
                <AppFooterOne />
            </main>
        </>
    )
}

export default MarketingProjectDetails;