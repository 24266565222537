import {
  collection,
  addDoc,
  getDocs,
  limit,
  query,
  orderBy,
  startAfter,
  getDoc,
  doc,
  setDoc,
  deleteDoc,
  where,
} from "@firebase/firestore";

import React from "react";

import firebase, { db  } from "../firbase";

var Api = collection(db, "brands");



export const getBrands = async () => {
  var formatData = [];
  const q = query(Api, orderBy("created", "desc"), limit(20));
  return await getDocs(q).then((snap) => {

    snap.docs.forEach((item) => {
      formatData.push({ id: item.id, ...item.data() });
    });
    return formatData;
  });
};

export const getBrandsPage = async (setLastVisible, lastVisible) => {
  var formatData = [];

  const q = query(
    Api,
    orderBy("created", "desc"),
    limit(5),
    startAfter(lastVisible)
  );
  return await getDocs(q).then((snap) => {
    setLastVisible(snap.docs[snap.docs.length - 1]);
    snap.docs.forEach((item) => {
      formatData.push({ id: item.id, ...item.data() });
    });
    return formatData;
  });
};

export const getBrandsByname = async (name) => {
  var formatData = [];
  // const q = query(Api,where("title", '>=', name),where('title', '<=', name+ '\uf8ff'));
  const q = query(Api,where("keywords", "array-contains-any", name.split(" ")));
  return await getDocs(q).then((snap) => {
    snap.docs.forEach((item) => {
      formatData.push({ id: item.id, ...item.data() });
    });
    return formatData;
  });
};

export const getSingleBrands = async (id) => {
  var formatData = {};
  const data = await getDoc(doc(db, "brands", id));

  formatData = { id: data.id, ...data.data() };
  return formatData;
};






