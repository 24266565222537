import React from 'react';
import SEO from '../../common/SEO';
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import AppCtaLayoutOne from '../../component/app/footer/CtaLayoutOne';
import AppFooterOne from '../../common/app/FooterOne';
import AppHeader from '../../common/app/HeaderOne';
import { useTranslation } from 'react-i18next';
import AppOfferForm from '../../component/app/forms/offer';



const AppOfferContact = () => {
    const { t } = useTranslation();
    return (
        <>
            <SEO title="Blog Grid" />
   
            <main className="main-wrapper">
            <AppHeader />
                <BreadCrumbOne 
                    title={t("offer")}
                    page={t("offer")}
                />

            <div className="section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="contact-form-box shadow-box mb--30">
                                
                                <AppOfferForm />
                         
                            </div>
                        </div>
                      
                    </div>
                </div>
                <ul className="list-unstyled shape-group-12">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Bubble" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Bubble" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="Circle" /></li>
                </ul>
            </div>

            <AppCtaLayoutOne />
                <AppFooterOne />
            </main>
        </>
    )
}

export default AppOfferContact;