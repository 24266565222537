import React from 'react';
import { Link } from 'react-router-dom';
import CaseStudyData from '../../data/casestudy/CaseStudyData.json';
import Tilty from 'react-tilty';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';
import { slugify } from '../../utils';
import { useTranslation } from 'react-i18next';

const allData = CaseStudyData;

const AppCaseStudyProp = () => {
    const { t } = useTranslation();
    const CaseLeftThumb = ({data}) => {
        return (
            <>
            <div className="col-lg-5">
                <div className={`case-study-featured-thumb thumb-${data.id}`}>
                <Tilty perspective={2000}>
                    <img src={process.env.PUBLIC_URL + data.thumb} alt="Case Study" className='img rounded-30 min-h-500 mb-4'/>
                </Tilty>
                </div>
            </div>
            <div className="col-xl-6 col-lg-6 offset-xl-1">
                <div className="case-study-featured">
                <div className="section-heading heading-left">
                    <span className="subtitle">{t(`home.${data.subKey}`)}</span>
                    <h2 className="title">{t(`home.${data.titleKey}`)}</h2>
                    <p>{t(`home.${data.decsKey}`)}</p>
                    <Link to={process.env.PUBLIC_URL + `${data.link}`} className="axil-btn btn-fill-primary btn-large">{t(`home.viewService`)}</Link>
                </div>
         
                </div>
            </div>
            </>
        )
    }
    
    const CaseRightThumb = ({data}) => {
        return (
            <>
            <div className="col-lg-5 offset-xl-1 order-lg-2">
                <div className={`case-study-featured-thumb thumb-${data.id}`}>
                <Tilty perspective={2000}>
                    <img src={process.env.PUBLIC_URL + data.thumb} alt="Case Study" className='img rounded-30 min-h-500 mb-4' />
                </Tilty>
                </div>
            </div>
            <div className="col-lg-6 order-lg-1">
                <div className="case-study-featured">
                <div className="section-heading heading-left">
                    <span className="subtitle">{t(`home.${data.subKey}`)}</span>
                    <h2 className="title">{t(`home.${data.titleKey}`)}</h2>
                    <p>{t(`home.${data.decsKey}`)}</p>
                    <Link to={process.env.PUBLIC_URL + `${data.link}`} className="axil-btn btn-fill-primary btn-large">{t(`home.viewService`)}</Link>
                </div>
           
                </div>
            </div>
            </>
        )
    }

    return (
        <>
            {allData.map((data) => (
                <div className="row" key={data.id}> 
                    {(data.id % 2  === 0) ? <CaseRightThumb data={data}/> : <CaseLeftThumb data={data}/>}
                </div>
            ))}
        </>
    )
}

export default AppCaseStudyProp;