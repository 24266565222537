import React, { useEffect, useState } from 'react';
import BcrumbBannerTwo from '../../../elements/breadcrumb/BcrumbBannerTwo';
import VideoOne from '../../../component/video/VideoOne';
import SEO from '../../../common/SEO';
import AppHeader from '../../../common/app/HeaderOne';
import AppCtaLayoutOne from '../../../component/app/footer/CtaLayoutOne';
import AppFooterOne from '../../../common/app/FooterOne';
import BusinessTestimonialItem from '../../../component/app/business/TestimonialPropTwo';
import ProjectData from "../../../data/casestudy/business.json";
import SectionTitle from '../../../elements/section-title/SectionTitle';
import LogisticsSectionTitle from '../../../component/app/logistics/SectionTitle';
import { useTranslation } from 'react-i18next';
import BusinessSubSection from '../../../component/app/business/subSectionTitle';
// const allProjectData = ProjectData;

const AllData = ProjectData;
const BusinessProjectDetails = () => {
    const { t } = useTranslation();
    const [getAllItems] = useState(AllData);
    const [visiableProject] = useState(15);
    const [activeFilter, setActiveFilter] = useState("");
    const [visibleItems, setVisibleItems] = useState([]);
    useEffect(() => {
        setVisibleItems(getAllItems.filter((item) => item.id <= visiableProject));
    }, []);



    return (
        <>
            <SEO title={t("business.title")}/>
            <main className="main-wrapper">
                <AppHeader />
                <BcrumbBannerTwo
                    title={t("business.title")}
                    paragraph=""
                    mainThumb="/images/home/5.jpg"
                />
                <section className="section-padding single-portfolio-area">
                    <div className="container">
                        <div class="row">
                            <LogisticsSectionTitle
                                title={t("business.aboutTitle")} description={t("business.aboutDesc")}
                            />
                        </div>
                        <div class="row">
                            <SectionTitle
                                subtitle=""
                                title={t("business.ourServicesTitle")}
                                textAlignment="heading-left mb--40"
                                textColor=""
                            />
                        </div>
                        {/* <div className='row mb-5'>
                        <BusinessSubSection
                            title={t("business.beauty")}
                            img={"images/business/11.png"}
                            to={"Request to join the Beauty platform to provide services for booking beauty salon appointments (service for Saudi clients)"}
                            description={<>
                                <p>{t("business.12")}</p>
                                <p>{t("business.beautyDesc1")}</p>
                                <p>{t("business.beautyDesc2")}</p>
                                <ul className="list-style">

                                    <li>
                                        {t("business.beautyTitle1")}
                                    </li>
                                    <li>
                                        {t("business.beautyTitle2")}
                                    </li>
                                    <li>
                                        {t("business.beautyTitle3")}
                                    </li>
                                    <li>
                                        {t("business.beautyTitle4")}
                                    </li>
                                    <li>
                                        {t("business.beautyTitle5")}
                                    </li>
                                    <li>
                                        {t("business.beautyTitle6")}
                                    </li>
                                </ul>
                                <br></br>
                                <p>{t("business.beautyDesc3")}</p>
                            </>}

                        />
                        </div> */}
                        <div className="row">
                            {visibleItems.map((data, index) => (
                                <div className='col-12 col-md-4'>
                                    <BusinessTestimonialItem title={
                                        <>
                                            {index + 1}.
                                        </>
                                    }
                                        to={data.title}
                                        img={data.image}
                                        dec={
                                            <div >

                                                <p> {t(`business.${data.id}`)}</p>
                                            </div>
                                        }
                                    />
                                </div>
                            ))}


                        </div>
                       
                    </div>
                </section>
                {/* <VideoOne /> */}
                <AppCtaLayoutOne />
                <AppFooterOne />
            </main>
        </>
    )
}

export default BusinessProjectDetails;