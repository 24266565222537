import React from 'react';

// const Data = [
//     {
//         image: "/images/app-brand/brand-4.png"
//     },
//     {
//         image: "/images/app-brand/brand-5.png"
//     },
//     {
//         image: "/images/app-brand/brand-6.png"
//     },
//     {
//         image: "/images/app-brand/brand-7.png"
//     },
//     {
//         image: "/images/app-brand/brand-8.png"
//     },
//     {
//         image: "/images/app-brand/brand-9.png"
//     },
// ]


const AppBrandItem = ({dataList}) => {
    return (
        <>
            {dataList.map((data, index) => (
                <div className="col-lg-4 col-6" key={index}>
                    <div className="brand-grid text-center">
                        <img src={data.img}  alt={`nebraska-${data.title_en}`}  className='rounded-full' style={{borderRadius:"100%"}} />
                    </div>
                </div>
            ))}
        </>
    )
}

export default AppBrandItem;