import React from 'react';
import SEO from '../../common/SEO';
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import SectionTitle from '../../elements/section-title/SectionTitle';
import PricingOne from '../../component/pricing/PricingOne';
import AppCtaLayoutOne from '../../component/app/footer/CtaLayoutOne';
import AppFooterOne from '../../common/app/FooterOne';
import AppHeader from '../../common/app/HeaderOne';
import { t } from 'i18next';


const OfferPage = () => {

    return (
        <>
        <SEO title={t("offer")} />
            <main className="main-wrapper">
        <AppHeader />
 
                <BreadCrumbOne 
                title={t("offer")}
                page={t("offer")}
                />
                <div className="section section-padding">
                    <div className="container">
                        <img src={process.env.PUBLIC_URL + `images/img.jpg`} className="w-full rounded-4" style={{width:"100%"}} alt="Illustration" />
                        <h4 className='mt-4'>{t("offerTitle")} </h4>

                        <h1></h1>
                        {/* <SectionTitle 
                            subtitle="Pricing Plan"
                            title="Find the Right Plan."
                            description="Flexible pricing options for freelancers <br> and design teams."
                            textAlignment="mb-0"
                            textColor=""
                        /> */}
                        <PricingOne />
                    </div>
                    <ul className="shape-group-3 list-unstyled">
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"} alt="shape" /></li>
                    </ul>
                </div>
                <AppCtaLayoutOne />
                <AppFooterOne />
            </main>
        </>
    )
}

export default OfferPage;