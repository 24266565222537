import i18next from 'i18next';
import React from 'react';


const LogisticsTestimonialItem = ({ colSize, layoutStyle,title,dec,img,icon }) => {
    return (
        <>
            
            <div className={`${colSize}`} >

                <div className={`testimonial-grid position-relative ${layoutStyle ? layoutStyle : ""}`}>
                    <div className='icon position-absolute' style={{right:i18next.language == "ar" ?"auto" :  0 ,left:i18next.language == "ar" ?0 :  "auto" }}>
                        {icon}
                    </div>
                    <div className='min-h-250'>
                    <h1 className="text-primary h3">{title}</h1>
                    {img &&
                        <img src={`${process.env.PUBLIC_URL}/${img}`} className="rounded-4"  style={{height:"350px",width:"100%",objectFit:"cover",objectPosition:"center" }} alt="Blog Author" />}
                    {dec}
                    </div>
                </div>
            </div>
        </>
    )
}

export default LogisticsTestimonialItem;