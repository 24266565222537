import { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';


const LogisticsSubSection = ({ img, title, description,to }) => {
    return (
        <div className="row" >
            <div className='col-12 col-md-4 text-center'>

                <div className="">
                    {
                        <img src={`${process.env.PUBLIC_URL}/${img}`} className=" img rounded-30 min-h-500 mb-4" height={"500px"} width={"100%"} />
                    }
            <Link to={`/request/Logistics services/${to}`} className="axil-btn btn-fill-primary  btn-large mx-auto btn-primary text-white ">{t("requestService")}</Link>
                </div>
            </div>
            <div className='col-12 col-md-8'>
                <h3 className="h2 text-primary">
                    {title}
                </h3>

                {description}
            </div>
            {/* <Link className="axil-btn btn-borderd btn-large" to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}>Read More</Link> */}
        </div>
    )
}

export default LogisticsSubSection;