import React from 'react';
import { Link, useParams } from 'react-router-dom';
import BcrumbBannerTwo from '../../../elements/breadcrumb/BcrumbBannerTwo';
import SEO from '../../../common/SEO';
import AppHeader from '../../../common/app/HeaderOne';
import AppCtaLayoutOne from '../../../component/app/footer/CtaLayoutOne';
import AppFooterOne from '../../../common/app/FooterOne';
import MarketingSubSection from '../../../component/app/markting/subSectionTitle';
import TechnicalHeader from '../../../component/app/technical/header';
import TechnicalSubSection from '../../../component/app/technical/subSectionTitle';
import { useTranslation } from 'react-i18next';
import trans from "../../../i18n/locales/en/translation.json"

// const allProjectData = ProjectData;

const TechnicalProjectDetails = () => {
    const { t } = useTranslation();
    const params = useParams();



    return (
        <>
            <SEO title={t("technical.title")} />

            <main className="main-wrapper">
                <AppHeader />
                <BcrumbBannerTwo
                    title={t("technical.title")}
                    paragraph=""
                    mainThumb="/images/home/4.png"
                />
                <section className="section-padding single-portfolio-area">
                    <div className="container">
                    <div class="row">
                            <TechnicalHeader
                                title={
                                    ""
                                }
                                dec={t("technical.desc")}
                                img="images/technical/1.jpg"
                            />
                        </div>
                        <div className="row">
                            <h1>{t("technical.services")} </h1>
                            <div className="mt-5">
                                <TechnicalSubSection
                                          to={trans.technical.s1title}
                                    title={t("technical.s1title")}
                                    description={
                                        <>
                                            <p>
                                            {t("technical.s1desc")}
                                            </p>
                                    
                                        </>
                                    }
                                    img={"images/technical/2.jpg"}
                                />
                            </div>

                            <div className="mt-5">
                                <TechnicalSubSection
                                           to={trans.technical.s2title}
                                    title={t("technical.s2title")}
                                    description={
                                        <>
                                            <p>
                                            {t("technical.s2desc")}
                                            </p>
                                        </>
                                    }
                                    img={"images/technical/3.jpg"}
                                />
                            </div>

                            <div className="mt-5">
                                <TechnicalSubSection
                                      to={trans.technical.s3title}
                                    title={t("technical.s3title")}
                                    description={
                                        <>
                                            <p>
                                            {t("technical.s3desc")}
                                            </p>
                                        </>
                                    }
                                    img={"images/technical/4.jpg"}
                                />
                            </div>




                        </div>
                    </div>
                </section>

                <AppCtaLayoutOne />
                <AppFooterOne />
            </main>
        </>
    )
}

export default TechnicalProjectDetails;