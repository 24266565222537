import React from 'react';



const LogisticsHeader = ({ title, img, dec }) => {



    return (
        <>
            <div class="col-12">
                <div className="">
                    <h3 className="title">
                        <h1>{title}</h1>
                    </h3>
                    <div className="author">
                        <div className="author-thumb">
                            <img src={`${process.env.PUBLIC_URL}/${img}`} className="rounded-4" alt="Blog Author" />
                        </div>
                        <div className="info mt-4">
                            <h6 className="author-name">
                                {dec}
                            </h6>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LogisticsHeader;