import i18next from 'i18next';
import React, { useState } from 'react';
import Logo from '../../elements/logo/Logo';
import { ChangeLanguage } from '../../i18n';

import OffcanvasMenu from '../header/./OffcanvasMenu';
import StickyHeader from '../header/./StickyHeader';

import MobileMenu from '../header/MobileMenu';

import AppNav from './AppNav';
import AppMobileMenu from './MobileMenu';


const AppHeader = () => {

    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const OffcanvasHandleClose = () => setShowOffcanvas(false);
    const OffcanvasHandleShow = () => setShowOffcanvas(true);

    const sticky = StickyHeader(100);

    const MobileMenuHandler = () => {
        document.querySelector('.mobilemenu-popup').classList.toggle("show");
        document.querySelector('body').classList.toggle("mobilemenu-show");
        var elements = document.querySelectorAll('.mobilemenu-popup .menu-item-has-children > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.axil-submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
    }


    return (
        <>
            <header className="header axil-header header-style-1 bg-white">
                <div className={`axil-mainmenu ${sticky ? "axil-sticky" : "py-2"}`}>
                    <div className="container">
                        <div className="header-navbar">
                            <div className="header-logo">
                                <Logo limage="/images/nebraska-logo.png"
                                dimage="/images/nebraska-logo.png"
                                simage="/images/nebraska-logo.png"
                                />
                            </div>
                            <div className="header-main-nav">
                                <AppNav />
                            </div>
                            <div className="header-action">
                                <ul className="list-unstyled">
                                    {/* <li className="sidemenu-btn d-lg-block d-none">
                                        <button className="btn-wrap" onClick={OffcanvasHandleShow}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </button>
                                    </li> */}
                                    <li>
                                    <button
                                        className="axil-btn btn-fill-primary btn-primary text-white px-2 py-1 my-2"
                                        onClick={() =>
                                        ChangeLanguage(i18next.language == "ar" ? "en" : "ar")
                                        }
                                    >
                                        {i18next.language == "ar" ? "En" : "Ar"}
                                    </button>
                                    </li>
                                    <li className="mobile-menu-btn sidemenu-btn d-lg-none d-block">
                                        <button className="btn-wrap" onClick={MobileMenuHandler}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </button>
                                    </li>
                                    
                                    {/* <li className="my_switcher d-block d-lg-none">
                                        <SwitcherHeader />
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <OffcanvasMenu offcanvasShow={showOffcanvas} offcanvasHide={OffcanvasHandleClose} />
            <AppMobileMenu MobileHandler={MobileMenuHandler}/>
        </>
    )
}

export default AppHeader;