import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


const AppAbout = () => {
    const { t } = useTranslation();
    return (
      <div className="section-padding-equal mt-4">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="about-team">
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + "/images/nebraska-logo.png"} alt="thumbnail" />
                    </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="about-team">
                    <div className="section-heading heading-left">
                        <span className="subtitle">{t("home.AboutUs")}</span>
                        <h2>{t("home.AboutUsTitle")}</h2>  
                        <p> {t("home.AboutUsDesc")}</p>
                        <Link to="/about-us" className="axil-btn btn-large btn-fill-primary">{t("home.AboutUsRead")}</Link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default AppAbout;