import React from "react";
import { Link, useParams } from "react-router-dom";
import BcrumbBannerTwo from "../../../elements/breadcrumb/BcrumbBannerTwo";
import VideoOne from "../../../component/video/VideoOne";
import SEO from "../../../common/SEO";
import AppHeader from "../../../common/app/HeaderOne";
import AppCtaLayoutOne from "../../../component/app/footer/CtaLayoutOne";
import AppFooterOne from "../../../common/app/FooterOne";

import LogisticsHeader from "../../../component/app/logistics/header";
import LogisticsSectionTitle from "../../../component/app/logistics/SectionTitle";
import LogisticsTestimonialItem from "../../../component/app/logistics/TestimonialPropTwo";
import LogisticsSubSection from "../../../component/app/logistics/subSectionTitle";
import { useTranslation } from "react-i18next";
import trans from "../../../i18n/locales/en/translation.json"



const LogisticsProjectDetails = () => {
    const params = useParams();
    const { t } = useTranslation();

    return (
        <>
            <SEO title={t("logistics.title")} />

            <main className="main-wrapper">
                <AppHeader />
                <BcrumbBannerTwo
                    title={t("logistics.title")}
                    paragraph=""
                    mainThumb="/images/logistics/hero.png"
                />
                {/* Logistics service for Restaurants' and Companies' Owners start */}
                <section className="section-padding single-portfolio-area">
                    <div class="container mt-5 pt-5">
                        <div class="row">
                            <LogisticsHeader
                                title={t("logistics.serviceOneTitle")}
                                dec={t("logistics.servicesOneDesc")}
                                img="images/logistics/12.png"
                            />
                        </div>
                        <div class="row">
                            <LogisticsSectionTitle title={t("logistics.servicesOneFeatureTitle")} />
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <LogisticsTestimonialItem title={"1."}
                                    icon={<>
                                   
                                        <img src={`${process.env.PUBLIC_URL}/images/logistics/i1.png`}   width="60" height="60"  className="rounded-4" alt="Blog Author" />

                                    </>}
                                    dec={
                                        <p>{t("logistics.servicesFeatures1")}</p>
                                    } />
                                         <Link to={`/request/Logistics services/${trans.logistics.serviceOneTitle}`} className="axil-btn btn-fill-primary  btn-large mx-auto btn-primary text-white ">{t("requestService")}</Link>
                            </div>

                            <div class="col-12 col-md-4">
                                <LogisticsTestimonialItem title={"2."}
                                    icon={<>

<img src={`${process.env.PUBLIC_URL}/images/logistics/i2.png`}   width="60" height="60"  className="rounded-4" alt="Blog Author" />



                                    </>}
                                    dec={
                                        <p>{t("logistics.servicesFeatures2")}.
                                        </p>
                                    } />
                            </div>
                            <div class="col-12 col-md-4">
                                <LogisticsTestimonialItem title={"3."}
                                    icon={<>

<img src={`${process.env.PUBLIC_URL}/images/logistics/i3.png`}   width="80" height="80"  className="rounded-4" alt="Blog Author" />


                                    </>}

                                    dec={
                                        <>
                                            <p> {t("logistics.servicesFeatures3")}</p>
                                            <ul className="list-style">
                                                <li> {t("logistics.servicesFeatures31")}</li>
                                                <li>{t("logistics.servicesFeatures32")}</li>

                                            </ul>
                                        </>
                                    } />
                            </div>
                        </div>
                    </div>
                </section>
                {/* Logistics service for Restaurants' and Companies' Owners end */}
                <section className="single-portfolio-area">
                    <div class="container mt-5 pt-5">
                        <div class="row">
                            <LogisticsHeader
                                title={t("logistics.serviceTwoTitle")}
                                dec={t("logistics.servicesTwoDesc")}
                                img="images/logistics/11.jpg"
                            />
                        </div>
                        <div class="row mt-5">
                            <LogisticsSectionTitle title={t("logistics.servicesTwoOurGoalTitle")} description={t("logistics.servicesTwoOurGoalDesc")}  />
                        </div>
                        <div class="row mt-5">
                            <LogisticsSectionTitle title={t("logistics.servicesTwoDistinguishes")} />
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 ">
                                <LogisticsTestimonialItem title={
                                    <>
                                        1.   <span className="text-sc">{t("logistics.servicesTwoDistinguishesTitle1")}</span>
                                    </>
                                }
                                    img="images/logistics/3.jpg"
                                    dec={
                                        <div>
                                            <span className="text-sc">{t("logistics.servicesTwoDistinguishesTitle1")}</span>
                                            <p> {t("logistics.servicesTwoDistinguishesDesc1")}</p>
                                        </div>
                                    } />

                            </div>
                            <div class="col-12 col-md-6 ">
                                <LogisticsTestimonialItem title={
                                    <>
                                        2.   <span className="text-sc">{t("logistics.servicesTwoDistinguishesTitle2")}</span>
                                    </>
                                }
                                    img="images/logistics/4.jpg"
                                    dec={
                                        <div>
                                            <span className="text-sc">{t("logistics.servicesTwoDistinguishesTitle2")}</span>
                                            <p> {t("logistics.servicesTwoDistinguishesDesc2")}</p>
                                        </div>
                                    } />

                            </div>
                            <div class="col-12 col-md-6 ">
                                <LogisticsTestimonialItem title={
                                    <>
                                        3.   <span className="text-sc">{t("logistics.servicesTwoDistinguishesTitle3")}</span>
                                    </>
                                }
                                    img="images/logistics/5.jpg"
                                    dec={
                                        <div>
                                            <span className="text-sc">{t("logistics.servicesTwoDistinguishesTitle3")}</span>
                                            <p>{t("logistics.servicesTwoDistinguishesDesc3")}</p>
                                        </div>
                                    } />

                            </div>
                            <div class="col-12 col-md-6 ">
                                <LogisticsTestimonialItem title={
                                    <>
                                        4.   <span className="text-sc">{t("logistics.servicesTwoDistinguishesTitle4")}</span>
                                    </>
                                }
                                    img="images/logistics/6.jpg"
                                    dec={
                                        <div>
                                            <span className="text-sc">{t("logistics.servicesTwoDistinguishesTitle4")}</span>
                                            <p>{t("logistics.servicesTwoDistinguishesDesc4")}</p>
                                        </div>
                                    } />

                            </div>


                        </div>
                        <div className="row">
                        <div class="row mt-5">
                            <LogisticsSectionTitle title={t("logistics.servicesTwoServices")} />
                        </div>
                            <div className="mt-5">
                                <LogisticsSubSection
                                    to={trans.logistics.servicesTwoServices1title}
                                    title={t("logistics.servicesTwoServices1title")}
                                    description={
                                        <>
                                            <p>
                                            {t("logistics.servicesTwoServices1desc")}
                                            </p>
                                        </>
                                    }
                                    img={"images/logistics/7.jpg"}
                                />
                            </div>
                            <div className="mt-5">
                                <LogisticsSubSection
                                        to={trans.logistics.servicesTwoServices2title}
                                    title={t("logistics.servicesTwoServices2title")}
                                    description={
                                        <>
                                            <p>
                                            {t("logistics.servicesTwoServices2desc")}
                                            </p>

                                            <h6 className="h6">         {t("logistics.servicesTwoServices2titleP")} </h6>
                                            <ul className="list-style">

                                                <li> {t("logistics.servicesTwoServices2titleP1")}</li>
                                                <li>  {t("logistics.servicesTwoServices2titleP2")}
                                                </li>
                                                <li>
                                                    {t("logistics.servicesTwoServices2titleP3")}
                                                </li>
                                                <li>
                                                    {t("logistics.servicesTwoServices2titleP4")}
                                                </li>
                                                <li>
                                                {t("logistics.servicesTwoServices2titleP5")}
                                                </li>

                                            </ul>
                                        </>
                                    }
                                    img={"images/logistics/8.jpg"}
                                />
                            </div>
                            <div className="mt-5">
                                <LogisticsSubSection
                                               to={trans.logistics.servicesTwoServices3title}
                                    title={t("logistics.servicesTwoServices3title")}
                                    description={
                                        <>
                                            <p>
                                                {t("logistics.servicesTwoServices3desc")}
                                                </p>
                                                        <h6 className="h6">{t("logistics.servicesTwoServices3descP")}</h6>
                                        <ol className="list-style">

                                            <li>
                                                {t("logistics.servicesTwoServices3descP1")}
                                            </li>
                                            <li>
                                                {t("logistics.servicesTwoServices3descP2")}
                                            </li>
                                            <li>
                                                {t("logistics.servicesTwoServices3descP3")}
                                            </li>
                                            <li>
                                                {t("logistics.servicesTwoServices3descP4")}
                                            </li>
                                            
                                         

                                        </ol>
                                           
                                        </>
                                    }
                                    img={"images/logistics/9.jpg"}
                                />
                          
                            </div>
                            <div className="mt-5">
                                <LogisticsSubSection
                                                         to={trans.logistics.servicesTwoServices4title}
                                    title={t("logistics.servicesTwoServices4title")}
                                    description={
                                        <>
                                            <p>
                                            {t("logistics.servicesTwoServices4desc")}
                                            </p>
                                        </>
                                    }
                                    img={"images/logistics/10.jpg"}
                                />
                            </div>

                        </div>
                    </div>
                </section>
                {/* <VideoOne /> */}
                <AppCtaLayoutOne />
                <AppFooterOne />
            </main>
        </>
    );
};

export default LogisticsProjectDetails;
