import React from "react";
import { Link } from "react-router-dom";
import {
  FaAngleDown,
  FaFacebookF,
  FaGoogle,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import { ChangeLanguage } from "../../i18n";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const AppNav = () => {
  const { t } = useTranslation();
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className="menu-item-has-children">
          <Link to="#">
            {t("nav.Services")} <FaAngleDown />
          </Link>
          <ul className="axil-submenu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/business"}>
                {t("nav.BusinessAdministrative")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/logistics"}>
                {t("nav.LogisticsServices")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/marketing"}>
                {t("nav.MarketingServices")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/technical"}>
                {t("nav.TechnicalDepartment")}
              </Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to={process.env.PUBLIC_URL + "/about-us"}>
            {t("nav.WhoWeAre")}
          </Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/contact"}>
            {t("nav.Contact")}
          </Link>
        </li>
        <li>
          <div className="blog-share mt-3">
            <ul className="social-list list-unstyled d-flex align-items-center ">
              <li className="px-2 mb-3">
                <a target="_blank" href="https://www.facebook.com/NebraskaTechMena/about_contact_and_basic_info">
                  <FaFacebookF color="#d19b00" />
                </a>
              </li>
              <li className="px-2 mb-3">
                <a target="_blank" href="https://twitter.com/NebraskaTecMena">
                  <FaTwitter color="#d19b00" />
                </a>
              </li>
              <li className="px-2 mb-3">
                <a target="_blank" href="https://www.google.com/maps/place/Nebraska/@31.9540625,35.8344375,15z/data=!4m5!3m4!1s0x0:0x23daba3c8fd58eba!8m2!3d31.9540625!4d35.8344375">
                  <FaGoogle color="#d19b00" />
                </a>
              </li>
              <li className="px-2 mb-3">
                <a target="_blank" href="https://www.linkedin.com/in/nebraska-tech-mena-67a98b257/">
                  <FaLinkedin color="#d19b00" />
                </a>
              </li>
              <li className="px-2 mb-3">
                <a target="_blank" href="https://www.instagram.com/NebraskaTechmena/?fbclid=IwAR00HpQ0doXSNaVciSDPu1KqJig66Bff9aZCveV4ldq97nXmy1BeSbww-cU">
                  <FaInstagram color="#d19b00" />
                </a>
              </li>
            </ul>
          </div>
        </li>

      </ul>
    </nav>
  );
};

export default AppNav;
