import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';
import { useTranslation } from 'react-i18next';
import { createContact } from '../../../api/forms';

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
        Your Message has been successfully sent.
        </Alert>
    )
}

const AppContactForm = () => {
    const { t } = useTranslation();
    const form = useRef();

    const [ result, showresult ] = useState(false);
    const sendEmail =async (e) => {
        const {
            contact_name,
            contact_email,
            contact_phone,
            contact_message, } = form.current;
        var data = {
            name:contact_name.value,
            email:contact_email.value,
            phone:contact_phone.value,
            message:contact_message.value,
        }
        console.log(data)
        e.preventDefault();
        await  emailjs.sendForm('service_fvatet8', 'template_1w1jhna', form.current, 'Vandxgpmxt07j75JY')
        .then((result) => {
              createContact(data)
              console.log(result.text);
              form.current.reset();
              showresult(true);
          }, (error) => {
              console.log(error.text);
          });
      };

        setTimeout(() => {
            showresult(false);
        }, 5000);


    return (
        <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
        <div className="form-group">
            <label>{t("contact.formName")}</label>
            <input type="text" className="form-control" name="contact_name" required />
        </div>
        <div className="form-group">
            <label>{t("contact.formEmail")}</label>
            <input type="email" className="form-control" name="contact_email" required />
        </div>
        <div className="form-group">
            <label>{t("contact.formPhone")}</label>
            <input type="tel" className="form-control" name="contact_phone" required />
        </div>
        <div className="form-group mb--40">
            <label>{t("contact.formHow")}</label>
            <textarea className="form-control" name="contact_message" rows="4"></textarea>
        </div>
        <div className="form-group">
            <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">{t("contact.formGet")}</button>
        </div>
        <div className="form-group">
            {result ? <Result /> : null}
        </div>

    </form>
    )
}

export default AppContactForm;