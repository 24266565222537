import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaAngleRight } from "react-icons/fa";
import BlogData from "./NewsData.json";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { getNews } from '../../../api/news';


const allBlogData = BlogData;

const AppNewsListOne = ({ colSize, newsList }) => {
    const { t } = useTranslation();

    return (
        <>
            {!!newsList && newsList.map((data) => (
                <div className={`${colSize}`} key={data.id}>
                    <div className={`blog-list ${(data.id % 2 === 0) ? "border-start" : ""}`}>
                        <div className="post-thumbnail">
                            <Link to={process.env.PUBLIC_URL + `/news-details/${data.id}`}>
                                <img src={`${data.img}`} style={{ width: "300px" }} alt="Blog Post" />
                            </Link>
                        </div>
                        <div className="post-content">
                            <h5 className="title">
                                <Link to={process.env.PUBLIC_URL + `/news-details/${data.id}`}>
                                    {`${i18next.language == "ar" ? data.title : data.title_en}`}
                                </Link>
                            </h5>
                            <p> {i18next.language == "ar" ? data.brief_ar : data.brief_en}</p>
                            <Link to={process.env.PUBLIC_URL + `/news-details/${data.id}`} className="more-btn">
                                {t("news.homeButton")} <FaAngleRight />
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default AppNewsListOne;