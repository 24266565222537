export const appServices = [
    {
        id:1,
        title: "Administrative Services & Business Development",
        localKey:"home.servicesOneTitle",
        img: "/images/home/4.jpg",
        email:"ceo@nebraskajo.com",
        sub: [

            {
                id: 111,
                img: "/images/business/1.png",
                title: "Business development in the sales department",
                localKey: "business.1",
                email:"ceo@nebraskajo.com",
             
            },
            {
                id: 112,
                img: "/images/business/2.png",
                title: "Digitize sales to increase market share",
                localKey: "business.2",
                email:"ceo@nebraskajo.com",
                

            },
            {
                id: 113,
                img: "/images/business/3.png",
                title: "Outsourcing sales",
                localKey: "business.3",
                email:"ceo@nebraskajo.com",

            },
            {
                id: 114,
                img: "/images/business/4.png",
                title: "Request to improve the sales process",
                localKey: "business.4",
                email:"ceo@nebraskajo.com",
           
            },
            {
                id: 115,
                img: "/images/business/5.png",
                title: "Request to hire sales staff",
                localKey: "business.5",
                email:"ceo@nebraskajo.com",
                
            },
            {
                id: 116,
                img: "/images/business/6.png",
                title: "Request to train the current staff.",
                localKey: "business.6",
                email:"ceo@nebraskajo.com",
             
            },
            {
                id:118,
                img: "/images/business/8.png",
                title: "Research and development request",
                localKey: "business.7",
                email:"ceo@nebraskajo.com",

            },
            {
                id: 117,
                img: "/images/business/7.png",
                title: "Determine the goal & KPIs",
                localKey: "business.8",
                email:"ceo@nebraskajo.com",

            },
            {
                id: 119,
                img: "/images/business/9.png",
                title: "Request workflow digitization and potential client list formation experts",
                localKey: "business.9",
                email:"ceo@nebraskajo.com",
            },
            {
                id: 1110,
                img: "/images/business/10.png",
                title: "Request the best current business models to present and develop them",
                localKey: "business.10",
                email:"ceo@nebraskajo.com",
            },
            {
                id: 1112,
                img: "/images/business/12.png",
                title: "Request to join the food ordering platforms as Hunger Station, To You, Mr. Mandoob, Marsool (service for Saudi clients)",
                localKey: "business.11",
                email:"ceo@nebraskajo.com",
            },
            {
                id: 1111,
                img: "/images/business/11.png",
                title: "Request to join the Beauty platform to provide services for booking beauty salon appointments (service for Saudi clients)",
                localKey: "business.12",
                email:"ceo@nebraskajo.com",
            },
            {
                id: 1113,
                img: "/images/business/13.png",
                title: "Request to join the Kaso Platform that provides services to link merchants with suppliers",
                localKey: "business.13",
                email:"ceo@nebraskajo.com",
            },
            {
                id: 1114,
                img: "/images/business/14.png",
                title: "Request to join Marn Co. for electronic billing",
                localKey: "business.13",
                email:"ceo@nebraskajo.com",
            }
        ],
    },
    {
        id:2,
        title: "Logistics services",
        img: "/images/logistics/1.png",
        localKey:"home.servicesTwoTitle",
        email:"clo@nebraskajo.com",
        sub: [
            
            {
                id: 2201,
                img: "/images/business/13.png",
                title: "Logistics service for Restaurants' & Companies' Owners",
                localKey: "logistics.serviceOneTitle",
                email:"clo@nebraskajo.com",
            },
        
            {
                id: 2202,
                img: "/images/business/13.png",
                title: "Sea Freight",
                localKey: "logistics.servicesTwoServices1title",
                email:"cmo@nebraskajo.com",
            },
        
            {
                id: 2203,
                img: "/images/business/13.png",
                title: "Road Freight",
                localKey: "logistics.servicesTwoServices2title",
                email:"cmo@nebraskajo.com",
            },
        
            {
                id: 2204,
                img: "/images/business/13.png",
                title: "Commercial Services",
                localKey: "logistics.servicesTwoServices3title",
                email:"cmo@nebraskajo.com",
            },
            {
                id: 2205,
                img: "/images/business/13.png",
                title: "Delivery Stages of Trade Brokerage Service",
                localKey: "logistics.servicesTwoServices4title",
                email:"cmo@nebraskajo.com",
            },
        
        ],
    },
    {
        id:3,
        title: "Marketing services",
        img: "/images/home/2.jpg",
        localKey:"home.servicesThreeTitle",
        email:"cmo@nebraskajo.com",
        sub: [
            {
                id: 2204,
                img: "/images/business/13.png",
                title: "Brand identity & creative design",
                localKey: "marketing.titleS1",
                email:"cmo@nebraskajo.com",
            },
            {
                id: 2204,
                img: "/images/business/13.png",
                title: "Social Media Managing",
                localKey: "marketing.titleS2",
                email:"cmo@nebraskajo.com",
            },
            {
                id: 2204,
                img: "/images/business/13.png",
                title: "Advertising and Promotional Campaigns",
                localKey: "marketing.titleS3",
                email:"cmo@nebraskajo.com",
            },
            {
                id: 2204,
                img: "/images/business/13.png",
                title: "Photography and Advertising Video",
                localKey: "marketing.titleS4",
                email:"cmo@nebraskajo.com",
            },
            {
                id: 2204,
                img: "/images/business/13.png",
                title: "Content Creation",
                localKey: "marketing.titleS5",
                email:"cmo@nebraskajo.com",
            },
            {
                id: 2204,
                img: "/images/business/13.png",
                title: "Media Coverage",
                localKey: "marketing.titleS6",
                email:"cmo@nebraskajo.com",
            },
            {
                id: 2204,
                img: "/images/business/13.png",
                title: "Audio Services",
                localKey: "marketing.titleS7",
                email:"cmo@nebraskajo.com",
            },
            {
                id: 2204,
                img: "/images/business/13.png",
                title: "Add your company location on Google Map",
                localKey: "marketing.titleS8",
                email:"cmo@nebraskajo.com",
            },
        ],
    },
    {
        id:4,
        title: "Technical Department",
        img: "/images/home/3.jpg",
        localKey:"home.servicesFourTitle",
        email:"cto@nebraskajo.com",
        sub: [
            {
                id: 2204,
                img: "/images/technical/2.jpg",
                title: "Websites",
                localKey: "technical.s2title",
                email:"cto@nebraskajo.com",
            },
            {
                id: 22050,
                img: "/images/business/13.png",
                title: "One of Our Advantages is providing consulting for free",
                localKey: "technical.s1title",
                email:"cto@nebraskajo.com",
            },
            {
                id: 2204,
                img: "/images/business/13.png",
                title: "Smart Applications",
                localKey: "technical.s3title",
                email:"cto@nebraskajo.com",
            },
          
        ],
    }
]