import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from "react-bootstrap/Alert";
import { appServices } from "../../../data/casestudy/form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { createProjects } from "../../../api/forms";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Your Message has been successfully sent.
    </Alert>
  );
};

const AppProjectForm = () => {
  const { t } = useTranslation();
  const form = useRef();
  let { cat, subCat } = useParams();
  console.log(cat, subCat);
  const [emails, setEmails] = useState([]);
  const [selectedCat, setSelectedCat] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState([]);
  const [result, showresult] = useState(false);
  
  useEffect(() => {
    if (!!cat && !!subCat) {
      findSelected(cat, subCat);
    }
  },[])
  
  const findSelected = (title,sub) => {
    var data = appServices.find(obj => obj.title == title)
    var subData = data.sub.find(obj => obj.title == sub)
    onClickItem(data)
    onClickSubItem(subData)
  }


  const sendEmail = (e) => {
    console.log(email)
  
    e.preventDefault();
    const { name, email, phone, message, } = form.current
    const set = new Set(emails);
    const form_emails =Array.from(set)
    var data = {
      name: name.value,
      email: email.value,
      phone: phone.value,
      message: message.value,
      selectedServices : selectedCat.join(","),
      selectedCategory : selectedSubCat.join(",")
    }
    form_emails.map((email) => {
      emailjs
        .send(
          "service_umfm2r9",
          "template_ixi28il",
          {...data, emailTo: email,},
          "Vandxgpmxt07j75JY"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    })
    createProjects({...data, emailTo: form_emails,})
    form.current.reset();
    setSelectedCat([])
    setSelectedSubCat([])
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  const onClickItem = (data) => {
    var tempArry = [...selectedCat];
    if (tempArry.length > 0 && tempArry.includes(data.title)) {
      const newArr = tempArry.filter((e) => e !== data.title);
      setSelectedCat(newArr);
    } else {
      setSelectedCat((oldArray) => [...oldArray, data.title]);
   
    }
    console.log(emails);
  };
  const onClickSubItem = (data) => {
    var tempArry = [...selectedSubCat];
    var tempEmailArry = [...emails];
    if (tempArry.length > 0 && tempArry.includes(data.title)) {
      const newArr = tempArry.filter((e) => e !== data.title);
      const newArrEmails = tempEmailArry.filter((e) => e !== data.email);
      setSelectedSubCat(newArr);
      setEmails(newArrEmails)
    } else {
      setSelectedSubCat((oldArray) => [...oldArray, data.title]);
      setEmails((oldArray) => [...oldArray, data.email]);
    }
  };
  const CatItem = ({ data }) => {
    var test = appServices.find((x) => x.title === data.title)
    return (
      <>
      <div className="col-12 col-md-3 d-flex d-md-block">
        <button
          className="mb-4 contact-form-box shadow-box p-4 border-0 d-none d-md-block"
          style={{
            height: 400,
            backgroundColor:
              selectedCat.length > 0 && selectedCat.includes(data.title)
                ? "#d19b00"
                : "#fff",
          }}
          onClick={() => onClickItem(data)}
          type="button"
        >
          <img
            src={`${process.env.PUBLIC_URL}${data.img}`}
            className=" img rounded-30  mb-4"
            height={"200px"}
            width={"100%"}
            style={{ height: "200px" }}
          />

          <h6
            className={
              selectedCat.length > 0 && selectedCat.includes(data.title)
                ? "text-white"
                : "#000"
            }
            >
            {t(data.localKey)}
          </h6>
        </button>
        <button
          className="mb-4 contact-form-box shadow-box p-4 border-0 d-block d-md-none"
          style={{
           width:"100%",
            backgroundColor:
              selectedCat.length > 0 && selectedCat.includes(data.title)
                ? "#d19b00"
                : "#fff",
          }}
          onClick={() => onClickItem(data)}
          type="button"
        >
          <div className="d-flex justify-content-between  align-items-center">
            
          <img
            src={`${process.env.PUBLIC_URL}${data.img}`}
            className=" img rounded-30  "
            height={"100px"}
            width={"100px"}
            style={{ height: "100px" }}
          />

          <h6
            className={
              (selectedCat.length > 0 && selectedCat.includes(data.title)
                ? "text-white"
                : "#000")+ " " + "mb-0"
            }
            >
            {t(data.localKey)}
          </h6>
        </div>
        </button>
        </div>
        {selectedCat.length > 0 && selectedCat.includes(data.title) &&

        <div className="row d-block d-md-none mx-auto mb-4">
       
 
            <div>
              <div className="form-group mt-5">
                <h4>{t("project.SelectCategory")} {t(test.localKey)}</h4>
              </div>
              <div className="row border border-1 rounded-1">
                {test.sub.map((data,index) => {
                  return <SubItme data={data} index={index+1} key={`${data.id}${index}`} />;
                })}
              </div>
            </div>
          
        
          </div>
        }
      </>
    );
};

  const SubItme = ({ data ,index }) => {
    return (
      <div className="col-12 col-md-6 my-2">
        <button
          className=" contact-form-box shadow-box p-4 border-0 w-full w-100"
          style={{
            backgroundColor:
            selectedSubCat.length > 0 && selectedSubCat.includes(data.title)
                ? "#d19b00"
                : "#fff",
          }}
          onClick={() => onClickSubItem(data)}
          type="button"
        >
     
          <h6
            className={
                selectedSubCat.length > 0 && selectedSubCat.includes(data.title)
                ? "text-white mb-0"
                : "#000 mb-0"
            }
          >
                    {index}.{t(data.localKey)}
          </h6>
        </button>
      </div>
    );
  };

  return (
    <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
        <div className="row">
      <div className="form-group mb--40">
        <h4>{t("project.SelectCategory")}</h4>
      </div>
        {appServices.map((data) => {
          return <CatItem data={data} key={`${data.id}${data.title}`} />;
        })}
      </div>

      <div className="row d-none d-md-block">
        {selectedCat.map((title) => {
          var test = appServices.find((x) => x.title === title);
          return (
            <div>
              <div className="form-group mt-5">
                <h4>{t("project.SelectCategory")} {t(test.localKey)}</h4>
              </div>
              <div className="row border border-1 rounded-1">
                {test.sub.map((data,index) => {
                  return <SubItme data={data} index={index+1} key={`${data.id}${index}`} />;
                })}
              </div>
            </div>
          );
        })}
          </div>
      <div className="row mt-5">
    
          
        <div className="col-12 col-md ">
          <div className="form-group">
            <label>{t("project.formName")}</label>
            <input
              type="text"
              className="form-control"
              name="name"
              required
            />
          </div>
        </div>
        <div className="col-12 col-md">
          <div className="form-group">
            <label>{t("project.formEmail")}</label>
            <input
              type="email"
              className="form-control"
              name="email"
              required
            />
          </div>
        </div>
      </div>

      <div className="form-group">
        <label>{t("project.formPhone")}</label>
        <input
          type="tel"
          className="form-control"
          name="phone"
          required
        />
      </div>
      <div className="form-group mb--40">
        <label>{t("project.formDescribe")}</label>
        <textarea
          className="form-control"
          name="message"
          rows="4"
        ></textarea>
      </div>
   
          {/* <div className="row mt-4" >
          <div className="form-group mb--40">
                <label>Select Service From {test.title}</label>
              </div>              
          </div> */}
      <div className="form-group">
        <button
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
        >
         {t("project.button")}
        </button>
      </div>
      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
};

export default AppProjectForm;
