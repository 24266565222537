import React from 'react';
import SEO from '../../common/SEO';
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import AppCtaLayoutOne from '../../component/app/footer/CtaLayoutOne';
import AppFooterOne from '../../common/app/FooterOne';
import AppHeader from '../../common/app/HeaderOne';
import AppContactForm from '../../component/app/forms/Contact';
import { useTranslation } from 'react-i18next';



const AppContact = () => {
    const { t } = useTranslation();
    return (
        <>
            <SEO title={t("contact.Contact")} />
   
            <main className="main-wrapper">
            <AppHeader />
                <BreadCrumbOne 
                title={t("contact.Contact")}
                page={t("contact.Contact")}
                />

            <div className="section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6">
                            <div className="contact-form-box shadow-box mb--30">
                                <h3 className="title">{t("contact.formTitle")}</h3>
                                <AppContactForm />
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 offset-xl-1">
                            <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                                <h4 className="title">{t("contact.phone")}</h4>
                                <p>{t("contact.phoneDesc")}</p>
                                <h4 className="phone-number"><a href="tel:+96299979949" dir='ltr'>(962) 99979949</a></h4>
                            </div>
                            <div className="contact-info mb--30">
                                <h4 className="title">{t("contact.email")}</h4>
                                <p>{t("contact.emailDesc")}</p>
                                <h6 className="phone-number" style={{wordBreak:"break-word",fontSize:"2.2rem"}}><a href="mailto:info@nebraskajo.com">cmo@nebraskajo.com</a></h6>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="list-unstyled shape-group-12">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Bubble" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Bubble" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="Circle" /></li>
                </ul>
            </div>

            <AppCtaLayoutOne />
                <AppFooterOne />
            </main>
        </>
    )
}

export default AppContact;