import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from "react-bootstrap/Alert";
import { appServices } from "../../../data/casestudy/form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { createOffer } from "../../../api/forms";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Your Message has been successfully sent.
    </Alert>
  );
};

const AppOfferForm = () => {
  const { t } = useTranslation();
  const form = useRef();
  let { id } = useParams();

  const [emails, setEmails] = useState([]);
  const [result, showresult] = useState(false);

  useEffect(() => {}, []);

  const sendEmail = (e) => {
    console.log(email);

    e.preventDefault();
    const { name, email, phone, message,code } = form.current;
    const form_emails = ["cto@nebraskajo.com","cmo@nebraskajo.com","clo@nebraskajo.com"];
    var data = {
      name: name.value,
      email: email.value,
      phone: phone.value,
      message: message.value,
      selectedServices: `offer ${t(id)} - code ${code}`,
      selectedCategory: `offer ${t(id)} - code ${code}`,
    };
    form_emails.map((email) => {
      emailjs
        .send(
          "service_umfm2r9",
          "template_ixi28il",
          { ...data, emailTo: email },
          "Vandxgpmxt07j75JY"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
          );
        });
    createOffer({ ...data, emailTo: form_emails },)
    form.current.reset();

    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
      <div className="row">
        <div className="form-group mb--40">
          <h1>{t(id)}</h1>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12 col-md ">
          <div className="form-group">
            <label>{t("project.formName")}</label>
            <input type="text" className="form-control" name="name" required />
          </div>
        </div>
        <div className="col-12 col-md">
          <div className="form-group">
            <label>{t("project.formEmail")}</label>
            <input
              type="email"
              className="form-control"
              name="email"
              required
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md">
          <div className="form-group">
            <label>{t("project.formPhone")}</label>
            <input type="tel" className="form-control" name="phone" required />
          </div>
        </div>
        <div className="col-12 col-md">
          <div className="form-group">
            <label>{t("code")}</label>
            <input type="text" className="form-control" name="code"  />
          </div>
        </div>
      </div>

      <div className="form-group mb--40">
        <label>{t("project.formDescribe")}</label>
        <textarea className="form-control" name="message" rows="4"></textarea>
      </div>

      <div className="form-group">
        <button
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
        >
          {t("project.button")}
        </button>
      </div>
      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
};

export default AppOfferForm;
