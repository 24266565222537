import React from 'react';


const LogisticsSectionTitle = ({subtitle, title, description, textAlignment, textColor}) => {
    return (
        <div className={` text-left ${textColor}`}>
            <div className="subtitle" dangerouslySetInnerHTML={{__html: subtitle}}></div>
            <h2 className="title" dangerouslySetInnerHTML={{__html: title}}></h2>
            <h6 dangerouslySetInnerHTML={{__html: description}}></h6>
        </div>
    )
}

export default LogisticsSectionTitle;