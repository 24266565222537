import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


const BusinessTestimonialItem = ({ colSize, layoutStyle, title, dec, img, icon,to }) => {
    const { t } = useTranslation();
    console.log(to)
    return (
        <>
            <div className={`${colSize}`} >
                <div className={`testimonial-grid position-relative ${layoutStyle ? layoutStyle : ""}`}>
                    <div className='icon position-absolute' style={{right:0}}>
                        {icon}
                    </div>
                    <div className='min-h-250'>
                    <h1 className="text-primary h3">{title}</h1>
                    {img &&
                            <img src={`${process.env.PUBLIC_URL}${img}`} className="rounded-4" style={{height:"300px"}}  alt="Blog Author" />}
                        <div  style={{minHeight:"120px"}}>

                    {dec}
                        </div>
                 
                    </div>
                </div>
                        <Link to={`/request/Administrative Services & Business Development/${to}`} className="axil-btn btn-fill-primary btn-primary text-white mx-auto">{t("requestService")}</Link>
            </div>
        </>
    )
}

export default BusinessTestimonialItem;